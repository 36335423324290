import type { StripeCardElementOptions } from '@stripe/stripe-js';

export const stripeElementOptions: StripeCardElementOptions = {
  style: {
    base: {
      fontFamily: '"Helvetica Neue", Helvetical, Arial, sans-serif',
      fontSize: '14px',
      // lineHeight: '1.428',
      color: '#555555',
      backgroundColor: 'white',
      ':focus': {},
      '::placeholder': {
        color: '#ccc',
      },
    },
    invalid: {
      color: '#a94442',
    },
  },
  classes: {
    focus: 'focus',
    empty: 'empty',
    invalid: 'invalid',
  },
};
