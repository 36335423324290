<script lang="ts">
  import { onMount } from 'svelte';
  import {
    loadStripe,
    StripeCardCvcElement,
    StripeCardCvcElementChangeEvent,
    StripeCardExpiryElement,
    StripeCardExpiryElementChangeEvent,
    StripeCardNumberElement,
    StripeCardNumberElementChangeEvent,
  } from '@stripe/stripe-js';
  import { CardCvc, CardExpiry, CardNumber, Container, isServer } from 'svelte-stripe-js';
  import { stripeElementOptions } from './stripeElementsOptions';
  import countries from './countryCodes';
  import Button from '../Button.svelte';
  import LoadingSpinner from '../LoadingSpinner.svelte';
  import { errorProvider } from '$lib/error-handling/errorProvider';

  let clazz: string = '';
  export { clazz as class };

  export let disabled = false;

  export let onLocationChange: (country: string, postalCode: string) => Promise<void>;
  export let onSubmitPayment: (token: string, country: string, postalCode: string) => Promise<void>;
  export let buttonLabel = 'Submit Payment';

  let stripe: Awaited<ReturnType<typeof loadStripe>> = null;

  onMount(async () => {
    if (!isServer) {
      stripe = await loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);
    }
  });

  let countryCode: string = 'US';
  let postalCode: string = '';

  let stripeCardNumberElement: StripeCardNumberElement;
  let cardNumberError: string = '';
  let cardNumberOk = false;
  function cardNumberChange(e: CustomEvent<StripeCardNumberElementChangeEvent>) {
    cardNumberError = e.detail.error?.message || '';
    cardNumberOk = e.detail.complete && !e.detail.error;
  }

  let stripeCardExpiryElement: StripeCardExpiryElement;
  let cardExpirationError: string = '';
  let cardExpirationOk = false;
  function cardExpirationChange(e: CustomEvent<StripeCardExpiryElementChangeEvent>) {
    cardExpirationError = e.detail.error?.message || '';
    cardExpirationOk = e.detail.complete && !e.detail.error;
  }

  let stripeCardCvcElement: StripeCardCvcElement;
  let cardCVCError: string = '';
  let cardCvcOk = false;
  function cardCVCChange(e: CustomEvent<StripeCardCvcElementChangeEvent>) {
    cardCVCError = e.detail.error?.message || '';
    cardCvcOk = e.detail.complete && !e.detail.error;
  }

  let locationOk = false;
  let locationError = '';
  let locationChangeCount = 0;
  function locationChange() {
    locationError = '';
    const iteration = ++locationChangeCount;
    onLocationChange(countryCode, postalCode)
      .then(() => {
        locationOk = true;
        locationError = '';
      })
      .catch((err) => {
        if (iteration !== locationChangeCount) return;
        locationOk = false;
        locationError =
          typeof err === 'string' ? err : err?.message || 'Invalid country/zip combination';
      });
  }

  $: submitDisabled =
    disabled ||
    !cardNumberOk ||
    !cardExpirationOk ||
    !cardCvcOk ||
    !locationOk ||
    paymentProcessing;

  let serverError = '';
  let paymentProcessing = false;
  async function submit(evt: Event) {
    if (!stripe) return;
    if (submitDisabled) return;
    serverError = '';
    paymentProcessing = true;
    try {
      const { token, error } = await stripe.createToken(stripeCardNumberElement);
      if (error) {
        serverError = error.message || 'Error processing payment.';
        paymentProcessing = false;
        return;
      }
      await onSubmitPayment(token!.id, countryCode, postalCode);
    } catch (err: any) {
      serverError = typeof err === 'string' ? err : err?.message || 'Error processing payment.';
      if (err?.status >= 500) {
        errorProvider.error(err);
      }
    }
    paymentProcessing = false;
  }

  export function clear() {
    if (stripeCardNumberElement) stripeCardNumberElement.clear();
    if (stripeCardCvcElement) stripeCardCvcElement.clear();
    if (stripeCardExpiryElement) stripeCardExpiryElement.clear();
    postalCode = '';
    countryCode = 'US';
  }
</script>

<!-- <div class="bg-yellow-100 text-yellow-800 p-2 my-2">
  Aug. 22, 2022: Our payment processor, Stripe, is currently having trouble processing payments. If
  you receive an error about a zip code, please try again in a few hours. Check <a
    class="a text-blue-800"
    href="https://status.stripe.com/"
    rel="external">https://status.stripe.com/</a
  >
  for updates.
</div> -->
<div class={clazz}>
  {#if stripe}
    <Container {stripe}>
      <form on:submit|preventDefault={submit}>
        <div class="form-element">
          <!-- svelte-ignore a11y-label-has-associated-control -->
          <label
            >Card Number
            <CardNumber
              bind:element={stripeCardNumberElement}
              on:change={cardNumberChange}
              style={stripeElementOptions.style}
              classes={stripeElementOptions.classes}
            />
          </label>
          <div role="alert" class="validation-error">{cardNumberError}</div>
        </div>

        <div class="form-element flex">
          <div class="w-1/2">
            <!-- svelte-ignore a11y-label-has-associated-control -->
            <label
              >Expiration
              <CardExpiry
                on:change={cardExpirationChange}
                style={stripeElementOptions.style}
                classes={stripeElementOptions.classes}
              />
            </label>
            <div role="alert" class="validation-error">{cardExpirationError}</div>
          </div>
          <div class="ml-3 w-1/2">
            <!-- svelte-ignore a11y-label-has-associated-control -->
            <label
              >CVC
              <CardCvc
                on:change={cardCVCChange}
                style={stripeElementOptions.style}
                classes={stripeElementOptions.classes}
              />
            </label>
            <div role="alert" class="validation-error">{cardCVCError}</div>
          </div>
        </div>
        <div class="flex form-element">
          <div class="mr-3 min-w-1/5">
            <label for="zip-input">{countryCode === 'US' ? 'Zip' : 'Postal'} Code</label>
            <input
              bind:value={postalCode}
              on:change={locationChange}
              type="text"
              id="zip-input"
              placeholder="90210"
              class="w-full"
              class:invalid={!!locationError}
            />
          </div>
          <div class="flex-grow">
            <label for="country-selector">Country</label>
            <select
              on:change={locationChange}
              bind:value={countryCode}
              id="country-selector"
              class="w-full"
              class:invalid={!!locationError}
            >
              {#each countries as country}
                <option value={country.alpha2}>
                  {country.shortName || country.altName || country.name}
                </option>
              {/each}
            </select>
          </div>
        </div>
        <div class="m-2">
          <div role="alert" class="text-red">{locationError}</div>
          <div role="alert" class="text-red">
            <slot name="error" />
          </div>
          <div role="alert" class="text-red">
            {serverError}
          </div>
        </div>
        <slot />
        <div class="form-element text-right mt-4">
          <Button color="orange" class="w-48" disabled={submitDisabled}>
            {#if paymentProcessing}<LoadingSpinner
                label="Processing payment"
              />{:else}{buttonLabel}{/if}</Button
          >
        </div>
      </form>
    </Container>
  {/if}
</div>

<style lang="postcss">
  .form-element {
    label {
      @apply m-2;
    }
  }

  .validation-error {
    @apply text-red -mt-3 my-2 mx-2;
  }

  :global(.StripeElement),
  input,
  select {
    @apply border border-gray-200;
    background-color: white;
    display: block;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    /* line-height: 1.428; */
    border-radius: 4px;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

    &::placeholder {
      color: #ccc;
    }
  }

  :global(.focus),
  select:focus,
  input:focus {
    @apply outline-none;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08), 0 0 8px rgba(102, 175, 233, 0.6);
  }

  :global(div.invalid),
  input.invalid,
  select.invalid {
    @apply border-red text-red;
  }
</style>
